.info-block {
  &__information {
  display: flex;
  gap: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $text-secondary;

  svg {
    flex-shrink: 0;
  }

  &--info-icon {
    color: black;
  }

  &--warning-icon {
      color: #f1c21b;

    & path:nth-child(1) {
      fill: black; 
    }
  }

  
}

  &__text-container {
    display: flex;
    flex-direction: column;
  }

  &__title-text {
    color: $text-primary;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
  }
}