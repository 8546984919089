@import "styles/vars.scss";

.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .cell-wrapper{
    &__accordion {
      padding-right: $carbon--spacing-02;
      vertical-align: middle;
      svg {
        cursor: pointer;
      }
    }
  }
}

.ReactTable * {
  box-sizing: border-box
}

.ReactTable .rt-table {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  position: relative;
  min-height: 100px;
}

.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 1;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02)
}

.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15)
}

.ReactTable .rt-thead .rt-tr {
  text-align: center;
  background: $background-table;
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  padding: 0;
  line-height: normal;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
  &:hover {
    background:  $background-active;
  }
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6)
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6)
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  /*border-right: 0*/
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
  height: 48px;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis
}

.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px
}

.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: auto;
}

.ReactTable .rt-tbody .rt-tr-group-wrapper {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  background: $ui-background;
  &:hover {
    background: $hover-primary;
  }
}

.ReactTable .rt-tbody .rt-tr-group-wrapper:last-child {
  border-bottom: 0
}

.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer
}

.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex
}

.column-cell-name {
  display: none;
}

.ReactTable .rt-tr .rt-td {
  align-items: center,
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  -webkit-transition: .3s ease;
  transition: .3s ease;
  -webkit-transition-property: width, min-width, padding, opacity;
  transition-property: width, min-width, padding, opacity;
}

.column-cell-text-full-width {
  width: 100%;
}

.column-cell-text-restrictions-width {
  max-width: 100%;
}

.column-cell-with-copy-button {
  width: 100%;
}

@media (max-width: $mobile-menu-breakpoint) {
  .ReactTable .rt-tr {
    flex-wrap: wrap;
    padding: 9px 16px 24px;
    row-gap: 8px;
  }

  .ReactTable .rt-tr .rt-td {
    min-width: 50%;
    align-items: flex-start;
    max-width: 50%;
  }

  .ReactTable .rt-tr .rt-td:not(div[data-name="dots-menu"]):not(div[data-name="Name"]) {
    flex-direction: column;
  }

  .ReactTable .rt-th,
  .ReactTable .rt-td {
    white-space: break-spaces;
    overflow-wrap: break-word;
    padding: initial;
  }

  .ReactTable .rt-th, .ReactTable .rt-td:not(:last-child) {
    padding: 2px !important;
  }

  .ReactTable .rt-tbody .rt-td {
    border-right: 0;
  }

  .column-cell-text-field {
    color: #5B6871;
  }

  .ReactTable .rt-tbody .rt-tr-group {
    // margin-bottom: 2px;
    position: relative;
  }

.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 2px rgba(0, 0, 0, 0.05);
  background: $ui-background;
  &:hover {
    background: $hover-primary;
  }
}
//open-button

  .mobile-hidden {
    display: none;
  }

  .ReactTable .rt-tr-group-wrapper {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
  }

  .ReactTable .rt-tr-group-wrapper .open-button {
    background: rgba(255, 255, 255, 0.8);
    height: 40px;
    border: 0;
  }

  .cell-wrapper__accordion {
    position: absolute;
    bottom: -42px;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.8);
    height: 40px;
    align-items: center;
  }

  .column-cell-name {
    display: block;
    font-weight: 600;
    margin-bottom: 8px;
    color: #252C32;
  }

  div[data-name="dots-menu"] {
    min-width: 35px !important;
    max-width: 35px !important;
    order: -9;
    display: flex;
    justify-content: flex-end;
    
  }

  div[data-name="Id"], div[data-name="id"]  {
    text-transform: uppercase;
    order: -8;
  }

  div[data-name="Email"] {
    order: -8;
  }

  div[data-name="Role"] {
    order: -7;
  }


  .rt-tr .mobile-table-title {
    order: -10;
    color:#0077D4;
    font-weight: 600;
    font-size: 16px;
    align-self: center;
    max-width: initial !important;
    
    .column-cell-name {
        display: none;
      }
  }

  div[data-name="Description"] {
    max-width: initial !important;
  }

  .cell-language-hidden {
    min-width: 100% !important;

    &:nth-of-type(n + 6) {
      display: none;
    }
  }

  .cell-language-open {
    min-width: 100% !important;
  }

  div[data-name="Merchant"], div[data-name="UUID"], div[data-name="User"], div[data-name="Recipient"] {
    min-width: 100% !important;
  }

  div[data-name="UUID"] {
    order: -8;
  }

  div[data-name="Type"] {
    order: -7;
  }

  div[data-name="Status"] {
    order: -6;
  }

  div[data-name="Gateway"] {
    order: -5;
  }

  div[data-name="Amount"] {
    order: -4;
  }

  div[data-name="Created"] {
    order: -3;
  }

  div[data-name="Updated"] {
    order: -2;
  }

  div[data-name="credentials"] {
    order: 1;
  }

  div[data-name="Updated"], div[data-name="Created"], div[data-name="Gateway"] {
    display: block !important;
  }



  .ReactTable .rt-tbody {
    min-width: 0 !important;
    max-width: 100vw;
  }

  .ReactTable .rt-thead {
    display: none;
  }

  .expanded-row--wrap {
    border-left: 0;
  }

  .bx--modal-content__body .bx--row {
    flex-direction: column;
  }

  .mobile__search-form .bx--row {
    flex-wrap: nowrap;
  }

  .bx--row__mobile .bx--row {
    flex-wrap: nowrap;
  }



  .mobile__search-form .bx--col-md-16 {
    display: none;
  }

  .mobile__search-form form {
    display: flex;
    align-items: center;

    & .bx--btn{
      display: flex;
      justify-content: center;
      height: 48px;
      width: 48px;
      background-color: #fff;
      border: transparent;
      border-bottom: 1px solid #829CAB;
      color: #252C32;
      margin-left: 1px;
    }
  }

  .bx--search-button {
    display: flex;
    justify-content: center;
    height: 48px;
    width: 48px;
    background-color: #fff;
    border: transparent;
    border-bottom: 1px solid #829CAB;
    color: #252C32;
  }
  
  .bx--content .bx--grid {
    flex-grow: 1;
  }

  .bx--content {
    overflow: hidden;
  }
  // .bx--col-md-16, .bx--col-sm-10 {
  //   display: none;
  // }

  .bx--overflow-menu, .bx--overflow-menu__trigger {
    height: 1rem;
    width: 1rem;
    transform: rotate(90deg) scale(1.5);
  }

  .bx--link-panel {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.bx--tile--clickable::after {
      content: ">";
      color: #252C32;
      font-weight: 400;
      transform: scaleX(0.5) scaleY(1.2);
      font-weight: 500;
    }
  }

  div[data-name="Merchant"] .bx--link, div[data-name="Name"] .bx--link {
    font-size: 16px;
    font-weight: 600;
  }

  .header__container {
    display: none;
  }
}


.ReactTable .rt-th,
.ReactTable .rt-td:not(:last-child) {
  padding: 7px 16px;
}


.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  -webkit-transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  cursor: pointer
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0)
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10
}

.ReactTable .rt-tfoot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03)
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05)
}

.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 3px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  cursor: pointer;
  outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: .5;
  cursor: default
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center
}

.ReactTable .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
}

.ReactTable .-pagination .-pageJump input {
  width: 70px;
  text-align: center
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px
}

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5)
}

.ReactTable .-loading {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}

.ReactTable .-loading>div {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  -webkit-transition: all .3s cubic-bezier(.25, .46, .45, .94);
  transition: all .3s cubic-bezier(.25, .46, .45, .94)
}

.ReactTable .-loading.-active {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}

.ReactTable .-loading.-active>div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%)
}

.ReactTable input,
.ReactTable select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none
}

.ReactTable input:not([type="checkbox"]):not([type="radio"]),
.ReactTable select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.ReactTable input:not([type="checkbox"]):not([type="radio"])::-ms-expand,
.ReactTable select::-ms-expand {
  display: none
}

.ReactTable .select-wrap {
  position: relative;
  display: inline-block;
}

.ReactTable .select-wrap select {
  padding: 5px 15px 5px 7px;
  min-width: 100px
}

.ReactTable .select-wrap:after {
  content: '';
  position: absolute;
  right: 8px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  -webkit-transition: none !important;
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.rt-tr-row-period {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    padding: 6px 10px;
    background: #E5E9EB;
    box-shadow: 0px -1px 0px #FFFFFF;
    position: relative;
    width: 100%;
    height: 32px;

    &__container {
      background: #E5E9EB;
      height: 32px;
      width: 100%;
    }

    &-closed {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $text-secondary;
      padding: 3px 6px;
      height: 22px;
      background: #FFFFFF;
      border-radius: 10px;
      text-align: center;
    }

    &-opened {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $text-primary;
      padding: 3px 6px;
      height: 22px;
      background: #B0DEFF;
      border-radius: 10px;
      text-align: center;
    }

    &-text-container {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 10px;
      top: 5px;
    }
}

.header__container {
  background: transparent;
  height: 48px;
  width: 100%;
  z-index: 1;
}