@import "styles/vars.scss";

$visible-popup-z-index: 9000;


#sso-widget {
  position: relative;
  display: flex;
  align-items: center;
  --w-primary-color:  #{$text-primary};
  --w-sso-primary-color: #{$text-primary};
  --w-secondary-color: #{$text-secondary};
  --w-visible-popup-z-index: #{$visible-popup-z-index};

  .sso-widget__popup {
    left: unset !important;
    right: 10px !important;
    background-color: $background-default !important;
  }

  .sso-widget__toggle-btn:hover {
    background-color: $brand-color-blue-active !important;
  }
  .sso-widget__toggle-btn svg path {
    fill: $ui-01;
  }
}