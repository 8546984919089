.bx--text-input:-webkit-autofill {
  appearance: none;
  background: transparent !important;
  color: inherit !important;
  transition: background-color 5000s ease-in-out 0s;
}

.bx--search-wrapper,
.bx--text-input-wrapper {
  display: flex;

  &:hover {
    .bx--search,
    .bx--text-input {
      &-input:not(:disabled),
      &-button:not(:disabled) {
        border-bottom: 1px solid $input-hover-border-color;
      }
    }
  }

  &:focus-within {
    .bx--search-input,
    .bx--text-input {
      border-bottom: 1px solid $input-hover-border-color;
    }
  }


  .bx--text {
    &-input:not(:disabled) {
      &:hover {
        border-bottom: 1px solid $input-hover-border-color;
      }
    }
  }

  .bx--search,
  .bx--text {
    &:focus-within {
     + .bx--search-button {
       border-bottom: 1px solid $input-hover-border-color;
     }
    }

    &-input {
      padding: 14px 45px 14px 16px;
      border-bottom: 1px solid $input-border-color;
      color: $text-primary;
      @include placehoder-color($placeholder-color);

      &--inverted {
        background-color: $input-background-inverted;
      }

      &:focus,
      &:active {
        &:not(:disabled) {
          outline: 2px solid $input-hover-border-color;
          background-color: $input-background;
        }
      }

      &:focus {
        ~ .bx--search-close {
          &:hover {
            outline: 2px solid $input-hover-border-color;
            background-color: $hover-primary;
          }
        }
      }

      &:disabled {
        border-color: transparent;
        color: $input-background-disabled;
      }
    }

    &-button {
      border-bottom: 1px solid $search-border-color;
      box-shadow: none;
      @include button-theme-custom(
        $icon-primary,
        $icon-primary,
        $search-btn,
        $search-btn,
        $search-btn,
        $search-btn,
        $search-btn,
        $search-btn
      );

      &.bx--btn.bx--btn--icon-only {
        &:active:not([disabled]),
        &:focus{
          outline: none;
          border-top-color: transparent;
          border-bottom-color: $input-hover-border-color;
        }
      }

      &:disabled {
        border-bottom: 1px solid $search-border-color;
      }
    }

    &-close {
      &:before {
        content: none;
      }

      &:hover {
        background-color: $hover-primary;
        border-bottom-color: $input-hover-border-color;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .bx--label {
    color: $text-secondary;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
}

.bx--label {
  color: $text-secondary;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: inline-block !important;
}

.small-input__container-right .bx--form-item,
.small-input__container-right .bx--dropdown__wrapper,
.small-input__container-right .bx--list-box__wrapper {
  padding-right: $input-padding
}
.small-input__container-left .bx--form-item,  
.small-input__container-left .bx--dropdown__wrapper,
.small-input__container-left .bx--list-box__wrapper  {
  padding-left: $input-padding
}

.position-toggle-row {
  &--center {
    display: flex;
    align-items: center;
  }
}

.conditional-input {
  display: none;
}
.field-with-calendar-icon {
  position: absolute;
  top: 38px;
  right: 16px;
}

.align-input-without-label {
  display: flex;
  margin-top: 25px !important;
  align-items: baseline;

  &--center {
    display: flex;
    align-items: center;
    max-width: max-content;
  }
}

.two-inputs{
  &__container {
    display: flex;
    gap: 10px;
  }

  &__item {
    flex-grow: 0;
    width: 100%;

    input {
      padding-right: 32px !important;
    }
  }
}

@media (max-width: $mobile-menu-breakpoint) {
  .small-input__container-right .bx--form-item,
  .small-input__container-right .bx--dropdown__wrapper,
  .small-input__container-right .bx--list-box__wrapper {
    padding-right: 0px
  }
  .small-input__container-left .bx--form-item,  
  .small-input__container-left .bx--dropdown__wrapper,
  .small-input__container-left .bx--list-box__wrapper  {
    padding-left: 0px
  }
}